export const YanushiKanrikunLocalOrigin = 'localhost'
export const YanushiKanrikunStgOrigin = 'stg.yanushi-kanrikun.itandibb.com'
export const YanushiKanrikunPrdOrigin = 'yanushi-kanrikun.itandibb.com'

export const resolveEnv = () => {
  if (typeof window === 'undefined') {
    return ''
  }

  switch (window.location.hostname) {
    case YanushiKanrikunLocalOrigin:
      return 'development'
    case YanushiKanrikunStgOrigin:
      return 'staging'
    case YanushiKanrikunPrdOrigin:
      return 'production'
    default:
      throw new Error('Invalid Origin')
  }
}
